/******************************************************************
Stylesheet: Login Stylesheet
******************************************************************/


body.login {} 


#login {} /* the form box */

/*
This is the Wordpress logo in the admin area.
You'll have to load your own images and mess
with the width and height.
*/
#login h1 a {
	display: block;
	margin-bottom: bl();
	background: {
		image: url(../images/login-logo.png); // make sure to replace this!
		repeat: no-repeat;
		position: center;
		size: contain;
	}
	width: 280px;
	height: 100px;
	text-indent: -9999px;
	overflow: hidden;
}
 
form {
	margin-left: 8px;
	padding: 26px 24px 46px;
	font-weight: normal;
	background: white;
	border: 1px solid #E5E5E5;
	-webkit-border-radius: 3px;
	 -khtml-border-radius: 3px;
	   -moz-border-radius: 3px;
			border-radius: 3px;
	-webkit-box-shadow: none;
	 -khtml-box-shadow: none;
	   -moz-box-shadow: none;
			box-shadow: none;
}

body form .input {
	font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",sans-serif;
	font-weight: 200;
	font-size: 24px;
	width: 97%;
	padding: 3px;
	margin-top: 2px;
	margin-right: 6px;
	margin-bottom: 16px;
	border: 1px solid #E5E5E5;
	background: #FBFBFB;
	outline: none;
	-webkit-box-shadow: inset 1px 1px 2px rgba(200,200,200,0.2);
	   -moz-box-shadow: inset 1px 1px 2px rgba(200,200,200,0.2);
			box-shadow: inset 1px 1px 2px rgba(200,200,200,0.2);
}

#wp-submit {} 